import React from 'react';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import 'src/scss/bestshape.scss';
// hooks
import bestshapeMobileMedia from '../../hooks/bestshapeMobileMedia';
import bestshapeMedia from '../../hooks/bestshapeMedia';
import useMediaQuery from 'src/hooks/useMediaQuery';
// lib
import handlePageTransition from 'src/lib/handle-page-transition';
// components
import SEO from 'src/components/SEO';
import BestShapeContent from '../../components/best-shape/BestShapeContent';
import BestShapeContentMobile from '../../components/best-shape/BestShapeContentMobile';



function BestShapePage({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  const mobileImages = [];
  const images = [];
  const mobileMedia = bestshapeMobileMedia();
  const media = bestshapeMedia();

  mobileMedia.allCloudinaryMedia.edges.forEach(item => mobileImages.push(item.node.secure_url));
  media.allCloudinaryMedia.edges.forEach(item => images.push(item.node.secure_url));

  const vid01 =
  'https://player.vimeo.com/progressive_redirect/playback/736957485/rendition/1080p/file.mp4?loc=external&signature=579b86a14428e12c083af5684b89a6803234611c7ee50801268af179f24319b9';

  return (
    <div>
      <SEO title="Best Shape | Falkon Content" />
      <div className="bs">
        {isDesktop
          ? (<BestShapeContent images={images} videos={[vid01]} />)
          : (<BestShapeContentMobile images={mobileImages} videos={[vid01]} />)
        }
      </div>
    </div>
  );
}

export default React.memo(BestShapePage);
