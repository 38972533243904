import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import { FalkonLogo } from '../svgs';
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import { YoutubeLogo } from '../svgs/YoutubeLogo';
import VideoPlayer from 'src/components/VideoPlayer';

function BestShapeContent({ images, videos }) {
    const [
        bestShapeImg01,
        bestShapeImg02,
        bestShapeImg03,
        bestShapeImg04,
        bestShapeImg05,
        bestShapeImg06,
        bestShapeImg07,
        bestShapeImg08,
        bestShapeImg09,
        bestShapeImg10,
        bestShapeImg11,
        bestShapeImg12,
        bestShapeImg13,
    ] = images;
    const [bestShapeVid01] = videos;
    // const scrollOffset = [40, -20];
    const scrollOffset = [0, 0];

    return (
        <div>
            <SectionTrigger bgColor="#07120F" textColor="#fff">
                <div className="bs-hero">
                    <div className="bs-hero__container">
                        <div className="bs-hero__header">
                            <YoutubeLogo className="bs-hero__header-logo" />
                            <h3 className="main-heading bs-hero__header-hdg">
                                BEST SHAPE OF MY LIFE
                            </h3>
                            <p className="main-copy bs-hero__header-copy">
                                YOUTUBE ORIGINALS
                            </p>
                        </div>
                    </div>
                    <img className="bs-hero__img" src={bestShapeImg01} alt="" />
                </div>
            </SectionTrigger>
            <SectionTrigger
                bgColor="#F3766A"
                className="bs-section bs-section--1"
                textColor="#fff"
            />
            <SectionTrigger
                bgColor="#F7F0F0"
                className="bs-section bs-section--2"
                textColor="#F3766A"
            />
            <SectionTrigger
                bgColor="#EA5750"
                className="bs-section bs-section--3"
                textColor="#fff"
            />
            <SectionTrigger
                bgColor="#F3766A"
                className="bs-section bs-section--4"
                textColor="#fff"
            />
            <div className="bs__container">
                <h3 className="main-heading bs__heading bs__heading--1">
                    SYNOPSIS
                </h3>
                <p className="main-copy bs__copy bs__copy--1">
                    The sometimes touching, sometimes funny, always inspirational, creatively expressive, wildly adventurous, richly profound and always radically entertaining story that follows Will Smith  as he embarks on a mission to achieve a physical transformation to reach the Best Shape of His Life, but ends up a far more than a physical transformation and becomes a holistic journey  of mind, body and soul that delivers more of a life transformation.
                    <span>Produced by Westbrook Entertainment</span>
                </p>

                <p className="bs__copy bs__copy--5">
                    WATCH THE TRAILER
                </p>
                <VideoPlayer
                    className="bs__vid bs__vid--1"
                    src={bestShapeVid01}
                    poster={bestShapeImg04}
                />
                <h3 className="main-heading solution bs__heading bs__heading--2">
                    SOLUTION
                </h3>
                <p className="main-copy bs__copy bs__copy--2">
                    We took a really original approach, taking inspiration from the process itself, which largely disrupted the traditional approach to an unscripted series. 
                </p>
                <blockquote className="blockquote bs__blockquote bs__blockquote--1">
                    “Will Smith’s embarking on a major quest; he’s going to lose 20 pounds in 20 weeks…”
                </blockquote>
                <h3 className="main-heading approach bs__heading bs__heading--3">
                    APPROACH
                </h3>
                
                <p className="main-copy bs__copy bs__copy--3">
                    We deployed an equal balance of highly cinematic filmmaking, with lower-fi cameras, iPhones, stylized interviews in equal parts to breaking the fourth wall, where even the director stepped in front of the camera and gave on the fly commentary that further shaped the dimension of the story and capture the experience that was actually unfolding. 
                </p>

                <blockquote className="blockquote bs__blockquote bs__blockquote--2">
                    “…all while finishing his his memoir; he quickly hits a turning point that he didn’t see coming. ”
                </blockquote>

                <h3 className="main-heading impact bs__heading bs__heading--4">
                    IMPACT IMPACT
                </h3>

                <p className="main-copy bs__copy bs__copy--4">
                    The award-winning series averaged nearly 6M views per episode - one of the most watched Original Series ever on YTO and garnered extremely powerful reactions and conversations around the social stratosphere.
                </p>
               
                <img
                    className="bs__img bs__img--2"
                    src={bestShapeImg02}
                    alt=""
                />
                <img
                    className="bs__img bs__img--3"
                    src={bestShapeImg03}
                    alt=""
                />
                <Parallax className="bs__img bs__img--4" y={scrollOffset}>
                    <ParallaxImage src={bestShapeImg05} alt="" />
                </Parallax>
                <img
                    className="bs__img bs__img--5"
                    src={bestShapeImg06}
                    alt=""
                />
                <Parallax className="bs__img bs__img--6" y={scrollOffset}>
                    <ParallaxImage src={bestShapeImg07} alt="" />
                </Parallax>
                <img
                    className="bs__img bs__img--7"
                    src={bestShapeImg08}
                    alt=""
                />
                <img
                    className="bs__img bs__img--8"
                    src={bestShapeImg09}
                    alt=""
                />
                <img
                    className="bs__img bs__img--9"
                    src={bestShapeImg10}
                    alt=""
                />
                <img
                    className="bs__img bs__img--10"
                    src={bestShapeImg11}
                    alt=""
                />
                <img
                    className="global-box-shadow bs__img bs__img--11"
                    src={bestShapeImg12}
                    alt=""
                />
                <img
                    className="global-box-shadow bs__img bs__img--12"
                    src={bestShapeImg13}
                    alt=""
                />
            </div>
            <SectionTrigger
                bgColor="#F3766A"
                className="bs-section"
                textColor="#fff"
            />
        </div>
    )
}

export default BestShapeContent;